import React from 'react';


function OurBrochure() {
  return (
    <article className="wrapper our-brochure">
      <div className="container-fluid py-5 border-bottom border-tertiary-light">
        <div>
          <h1 className="font-weight-bolder underscore">Our<br />brochure</h1>
        </div>
        <div className="row text-center justify-content-center align-items-center">
          <div className="col-12">
            <img src="/assets/brochure.jpg" className="img-fluid d-none d-md-block" alt="Emvelia Technologies - Brochure" />
            <img src="/assets/brochure-sm.jpg" className="img-fluid d-block d-md-none" alt="Emvelia Technologies - Brochure" />
          </div>
          <div className="col-10 col-sm-8 d-flex align-items-center justify-content-center">
            <span>
              <a className="btn btn-primary btn-xl d-flex align-items-center" href="/assets/Emvelia Technologies - Brochure.pdf" download="Emvelia Technologies - Brochure.pdf">
                <i className="ai-pdf">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                  <span className="path4" />
                  <span className="path5" />
                  <span className="path6" />
                  <span className="path7" />
                  <span className="path8" />
                </i>
                <span className="ml-3">Download our brochure</span>
              </a>
            </span>
          </div>
        </div>

      </div>
    </article>
  );
}


export default OurBrochure;
