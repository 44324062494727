import React from 'react';
import Investor from 'components/investors';
import ArticlePreview from './articlePreview';
import TogetherBanner from '../../components/togetherBanner';
import SEO from '../../components/seo';
import OurBrochure from '../Home/ourBrochure';


function PressMedia() {
  const mediaData = [
    {
      imgSrc: '/assets/press/theportglobal.jpg',
      title: 'GREEK TECH GUIDE 2022 PREVIEW',
      description: '“Greek Tech Arriving on the Global Arena”',
      btnLabel: 'See Guide',
      date: 'February 3/2022 by The Port Global',
      link: 'https://issuu.com/theportglobal/docs/greek_tech_guide_preview_select',
      eventLabel: 'See Guide February 3/2022 by The Port Global',
    },
    {
      imgSrc: '/assets/press/vradini.jpg',

      title: 'Vradini, GR (Article)',
      description: '“Artlimes: A global alternative art platform”',
      btnLabel: 'Read article',
      date: 'December 11/2020 by Vradini',
      link: 'https://www.vradini.gr/artlimes-mia-pagkosmia-enallaktiki-platforma-technis/',
      eventLabel: 'Read article December 11/2020 by Vradini',
    },
    {
      imgSrc: '/assets/press/real-news.jpg',
      title: 'Real News, GR (Article)',
      description: '“Article in Real News newspaper, in Realmoney section”',
      btnLabel: 'Read article',
      date: 'January 26/2020 by Real News',
      link: 'assets/press/real-news-article.jpg',
      eventLabel: 'Read article January 26/2020 by Real News',
    },
    {
      imgSrc: '/assets/press/starx-gr.jpg',
      title: 'StarX, GR (TV Interview)',
      description: '“Artlimes on StarX show Greece in our Heart”',
      btnLabel: 'Watch video',
      date: 'January 8/2020 by Babis Petrakis',
      link: 'https://www.starx.gr/show/h-ellada-sthn-kardia-mas/episode/1722 ',
      eventLabel: 'Read article January 8/2020 by Babis Petrakis',
    },
    {
      imgSrc: '/assets/press/stores-magazine.jpg',
      title: 'Stores Magazine, US (Article)',
      description: '“Opening Doors, Online Marketplaces are Comforted by Flexible Platforms”',
      btnLabel: 'Read article',
      date: 'November 1/2018 by M.V. GREENE',
      link: 'https://www.storesmagazine-digital.com/nrfk/0818_october_november_2018/MobilePagedArticle.action?articleId=1437743#articleId1437743',
      eventLabel: 'Read article November 1/2018 by M.V. GREENE',
    },
    {
      imgSrc: '/assets/press/tv-nea.jpg',
      title: 'TV Nea (Press Release)',
      description: '“The Most Innovative Global Art Platform Opens its Offices in Athens, Greece”',
      btnLabel: 'Read article',
      date: 'May 31/2017 by tvnea.com',
      link: 'http://www.tvnea.com/2017/05/blog-post_231.html',
      eventLabel: 'Read article May 31/2017 by tvnea.com',
    },
    {
      imgSrc: '/assets/press/pr-web.jpg',
      title: 'PR Web (Press Release)',
      description: '“Artlimes Launches Innovative New Online Marketplace for Buyers and Sellers of Fine Art”',
      btnLabel: 'Read article',
      date: 'May 10/2017 by PR Web',
      link: 'http://www.prweb.com/releases/2017/05/prweb14320655.htm',
      eventLabel: 'Read article May 10/2017 by PR Web',
    },
    {
      imgSrc: '/assets/press/reaction-commerce.jpg',
      title: 'Reaction Commerce (Article)',
      description: '“Meet Lorenzo Campanis, CEO of Artlimes”',
      btnLabel: 'Read article',
      date: 'May 10/2017 by Reaction Commerce',
      link: 'https://blog.reactioncommerce.com/meet-lorenzo-campanis-ceo-of-artlimes/',
      eventLabel: 'Read article May 10/2017 by Reaction Commerce',
    },
  ];

  return (
    <>
      <SEO title="Press And Media" />

      <div className="pt-4 pt-lg-2" />
      <img src="/assets/press/header-press-media.jpg" className="img-fluid d-block d-lg-none mb-lg-5" alt=" " />
      <div className="d-flex justify-content-center text-center align-items-end mt-lg-1">
        <div className="container-fluid position-lg-absolute mt-lg-7">
          <div className="row justify-content-center align-items-center mt-lg-6">
            <div className="col-12 col-md-7 col-lg-6 mb-5">
              <div className="">
                <div className="font-weight-bolder underscore display-1">Press and Media</div>
                <h5 className="display-5 font-weight-light text-secondary-light">
                Our endeavours have been noted in several publications. <br />From local Newspapers to Online Reviews.
                  <br />
                  <br />
                Learn more about our latest stories from the art and culture sectors,<br />
                alternative events and insights into the Art world.
                </h5>
                <h4 className="font-weight-bolder my-4">Want to write about us?</h4>
                <div className="mt-3">
                  <a
                    className="btn btn-primary"
                    href="assets/emvelia-logos.zip"
                    download
                  >
                    Download Logos
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="/assets/press/header-press-media.jpg" className="img-fluid d-none d-lg-block" alt=" " />
      </div>
      <div className="container-fluid pt-6 my-6">
        <div className="row">
          {mediaData.map(({ imgSrc, title, description, btnLabel, date, link }) => <div className="col-12 col-md-4 text-center my-3" style={{ maxHeight: 545 }} key={title}>
            <ArticlePreview
              imgSrc={imgSrc}
              title={title}
              description={description}
              btnLabel={btnLabel}
              date={date}
              link={link}
            />
          </div>)}
        </div>
        <OurBrochure />
      </div>
      <TogetherBanner />
      <Investor />
    </>
  );
}

export default PressMedia;

