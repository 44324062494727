import React from 'react';


function ArticlePreview({ imgSrc, title, description, btnLabel, date, link }) {
  return (
    <div className="border h-100 d-flex flex-column align-items-center justify-content-center">
      <img className="img-fluid" src={imgSrc} alt=" " />
      <h5 className="font-weight-bold mt-3">{title}</h5>
      <h6 className="align-self-center px-3 px-md-5 font-weight-light">{description}</h6>
      <a
        className="btn btn-sm btn-outline-secondary my-3"
        href={link}
        target="_blank"
      >
        {btnLabel}
      </a>
      <div className="font-size-sm py-3">{date}</div>
    </div>
  );
}
export default ArticlePreview;

